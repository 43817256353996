import React from "react"
import Img from "gatsby-image"

function trim(str, maxLen, separator = " ") {
  if (str.length <= maxLen) return str
  return str.substr(0, str.lastIndexOf(separator, maxLen)) + "..."
}

const stripHTML = html => html.replace(/(<([^>]+)>)/gi, "")

const BlogPostCard = ({ image, title, excerpt }) => (
  <div className="blog-post">
    {image && image.localFile && (
      <Img
        style={{
          display: "block",
        }}
        fluid={{
          ...image.localFile.childImageSharp.fixed,
          aspectRatio: 300 / 200,
        }}
      />
    )}
    <div className="px-5 py-5">
      <h3
        className="has-text-weight-semibold mb-3"
        style={{
          fontSize: "16px",
          color: "#2E2E2E",
          lineHeight: "23px",
        }}
        dangerouslySetInnerHTML={{ __html: title }}
      ></h3>
      <p
        style={{
          fontFamily: "Outfit",
          fontSize: "14px",
          color: "#515C65",
          lineHeight: "24px",
        }}
        dangerouslySetInnerHTML={{ __html: trim(stripHTML(excerpt), 150) }}
      ></p>
    </div>
  </div>
)

export default BlogPostCard
