import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AgenciesMarquee from "../components/agencies-marquee"
import { clamp } from "lodash"
import ProductHero from "../components/product-hero"
import BlogPostCard from "../components/blog-post-card"

function trim(str, maxLen, separator = " ") {
  if (str.length <= maxLen) return str
  return str.substr(0, str.lastIndexOf(separator, maxLen))
}

const stripHTML = html => html.replace(/(<([^>]+)>)/gi, "")

class Blog extends React.Component {
  constructor(props) {
    super(props)
    this.showMoreItems = this.showMoreItems.bind(this)
    this.state = {
      showItems: 12,
      length: this.props.data.allWordpressPost.edges.length,
      posts: this.props.data.allWordpressPost.edges.slice(0, 12),
    }
    if (typeof window !== `undefined`) {
      window.addEventListener("scroll", () => {
        if (
          document.scrollingElement.scrollTop >
          document.scrollingElement.scrollHeight / 2.5
        ) {
          this.showMoreItems()
        }
      })
    }
  }

  showMoreItems() {
    this.setState(state => ({
      showItems: clamp(state.showItems + 6),
      posts: this.props.data.allWordpressPost.edges.slice(0, state.showItems),
    }))
  }

  render() {
    return (
      <Layout>
        <SEO
          title="inPowered Advertising Blog"
          description="Learn about cutting edge advertising strategies and solutions helping brands win."
        />
        <div class="blog-container ">
          <ProductHero title={[<strong>inPowered</strong>, " Blog"]} />
          <div class="blog-grid">
            {this.state.posts.map(({ node, index }) => (
              <Link to={node.slug} key={index} style={{ display: "flex" }}>
                <BlogPostCard
                  image={node.featured_media}
                  title={node.title}
                  excerpt={node.excerpt}
                />
              </Link>
            ))}
          </div>
          <div className="is-clearfix	"></div>
        </div>
        <AgenciesMarquee />
      </Layout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          excerpt
          slug
          featured_media {
            localFile {
              url
              childImageSharp {
                fixed(width: 600, height: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
